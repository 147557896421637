/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M10.95 1.804h0-.57v-.346a.45.45 0 10-.9 0v.346H4.52v-.346a.45.45 0 10-.9 0v.346H3.05h0a1.742 1.742 0 00-1.74 1.74v7.708h0a1.742 1.742 0 001.74 1.74h7.901a1.741 1.741 0 001.74-1.74v0-7.708 0a1.741 1.741 0 00-1.74-1.74zm.84 9.447a.842.842 0 01-.84.84h-7.9a.84.84 0 01-.84-.84V6.169h9.581v5.083zm.001-7.707v1.724H2.208V3.544a.84.84 0 01.84-.84h.571v.303a.45.45 0 10.9 0v-.303h4.96v.303a.45.45 0 10.9 0v-.303h.57a.841.841 0 01.841.84s0 0 0 0z" _fill="#B0B0B6" _stroke="#B4BAC6" stroke-width=".2"/>'
  }
})
